<template>
  <div class="holder">
    <h2></h2>
    <p></p>

    <h2></h2>

    <h1>
      K线图
      <span @click="playIn1('refKLineChart1')">入场</span>
      <span @click="playOut('refKLineChart1')">出场</span>
    </h1>
    <k-line-chart
      ref="refKLineChart1"
      id="refKLineChart1"
      customClass="c_kline1"
    />
  </div>
</template>
<script>
import kLineChart from "@/components/kLineChart/index_v2.0.vue";
import kLineData from "./kData.json";
export default {
  data: function () {
    return {
      kLineData: [],
    };
  },
  components: { kLineChart },
  methods: {
    playIn1(refName) {
      this.$refs[refName].KLineChart(this.kLineData, {
        label: (d) => d.label,
        value: (d) => d.k,
        width: 600,
        height: 300,
        marginTop: 20,
        marginBottom: 40,
        marginLeft: 50,
        marginRight: 50,
        duration: 500,
        delay: 100,
        ease: "easeCubicInOut",
        curveName: "curveLinear",
        xTickNumber: 3,
        yTickNumber: 6,
      });
    },

    playOut(refName) {
      this.$refs[refName].playOut();
    },
  },
  mounted() {
    this.kLineData = kLineData;
  },
};
</script>
<style lang="less" scoped>
.holder {
  padding: 20px;
}
h1 {
  font-size: 18px;
  margin-bottom: 8px;
  font-weight: normal;
  color: #333;
  span {
    padding: 6px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    background: #999;
    color: #fff;
    user-select: none;
    margin: 0 3px;
  }
  span:nth-of-type(1) {
    background: #447746;
  }
  span:nth-of-type(2) {
    background: #953e23;
  }
}
h2 {
  font-size: 15px;
  color: #000;
  margin-bottom: 10px;
  font-weight: bold;
  .new {
    color: #fff;
    background: #f00;
    padding: 0.5rem;
    border-radius: 0.3125rem;
    margin-right: 0.625rem;
  }
}
p {
  margin-bottom: 20px;
}

.c_kline1 {
  /deep/ .k_bar_positive {
    fill: #ff3b30;
  }
  /deep/ .k_bar_negative {
    fill: #39b24e;
  }
  /deep/ .kline_positive {
    stroke: #ff3b30;
  }
  /deep/ .kline_negative {
    stroke: #39b24e;
  }
}
</style>
